import { Button, Empty, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { ClearOutlined } from '@ant-design/icons';
import { TSellerHistoryEntity } from 'src/shared/api/queries/sellerBillingApi';
import { TableSettings } from 'src/entities/tableSettings';
import { ColumnsType } from 'antd/es/table';
import { Hooks } from 'src/shared/lib';
import { useSellerOperationTypes } from 'src/shared/lib/hooks/useSellerOperationTypes';

import { getColumns } from '../../PaymentsSuppliersPage.utils';
import { initState, PER_PAGE } from '../../PaymentsSuppliersPage.const';
import styles from '../../PaymentsSuppliersPage.module.less';
import { usePaymentsSuppliers } from '../../hooks/usePaymentsSuppliers';

export const Content: React.FC = () => {
  const { data, total, setSearchParams, searchParams, balances, loading, onExport } = usePaymentsSuppliers();
  const { columns, initColumnSettings } = getColumns();
  const [visibleColumns, setVisibleColumns] = useState(initColumnSettings);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const { suppliers, setParams: setSupplierSearchParams } = Hooks.useSearchSupplier();
  const { pavilions, setParams: setPavilionSearchParams } = Hooks.useSearchPavilion();
  const { users, setParams: setUserSearchParams } = Hooks.useSearchUser();
  const { typeOptions } = useSellerOperationTypes();

  const handleResetFilters = () => {
    setSupplierSearchParams(undefined);
    setPavilionSearchParams(undefined);

    setSearchParams?.(prev => ({
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  };

  const handleChangePage = (value: number) => {
    setSearchParams?.(prev => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        page: value,
      },
    }));
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    setSearchParams?.(prev => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        per_page: size,
      },
    }));
  };

  const handleSelectSupplier = (value: number) => {
    setSearchParams?.(prev => ({
      ...prev,
      userId: value || undefined,
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  };
  const handleSelectPavilion = (value: number) => {
    setSearchParams?.(prev => ({
      ...prev,
      pavilion_id: value || undefined,
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  };
  const handleSelectStatus = (value: string[]) =>
    setSearchParams?.(prev => ({
      ...prev,
      operation_types: value || undefined,
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  const handleSelectUser = (value: number) => {
    setSearchParams?.(prev => ({
      ...prev,
      created_by: value || undefined,
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  };

  const searchCreatedDateHandler = (data: { date_after: string; date_to: string }) => {
    setSearchParams?.(prev => ({
      ...prev,
      date_from: data.date_after ? new Date(data.date_after).toISOString() : undefined,
      date_to: data.date_to ? new Date(data.date_to).toISOString() : undefined,
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  };
  const searchSupplierHandler = (value: string) => {
    if (value) {
      setSupplierSearchParams({ ...initState, name: value });
    }
  };
  const resetSupplierHandler = () => {
    setSearchParams?.(prev => ({
      ...prev,
      userId: undefined,
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  };
  const searchPavilionHandler = (value: string) => {
    if (value) {
      setPavilionSearchParams({ ...initState, query: value });
    }
  };
  const resetPavilionHandler = () => {
    setSearchParams?.(prev => ({
      ...prev,
      pavilion_id: undefined,
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  };
  const searchUserHandler = (value: string) => {
    if (value) {
      setUserSearchParams({ ...initState, name: value });
    }
  };
  const resetUserHandler = () => {
    setSearchParams?.(prev => ({
      ...prev,
      userId: undefined,
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  };
  const searchPriceHandler = (value: string) => {
    setSearchParams?.(prev => ({
      ...prev,
      amount: Number(value) * 100,
      pagination: {
        ...prev?.pagination,
        page: 1,
      },
    }));
  };

  const getDateSearch = Hooks.useColumnSearchDate<TSellerHistoryEntity>(
    searchCreatedDateHandler,
    isActiveFilter,
    setIsActiveFilter,
    'created_at',
  );
  const getSupplierSearch = Hooks.useColumnAutocomplete<TSellerHistoryEntity>(
    searchSupplierHandler,
    handleSelectSupplier,
    resetSupplierHandler,
    suppliers?.items || [],
    isActiveFilter,
    setIsActiveFilter,
  );
  const getPavilionSearch = Hooks.useColumnAutocomplete<TSellerHistoryEntity>(
    searchPavilionHandler,
    handleSelectPavilion,
    resetPavilionHandler,
    pavilions?.items || [],
    isActiveFilter,
    setIsActiveFilter,
  );
  const getUserSearch = Hooks.useColumnAutocomplete<TSellerHistoryEntity>(
    searchUserHandler,
    handleSelectUser,
    resetUserHandler,
    users?.items || [],
    isActiveFilter,
    setIsActiveFilter,
  );
  const getStatusSearch = Hooks.useColumnMultiSelect<TSellerHistoryEntity>(
    handleSelectStatus,
    typeOptions,
    'Выбрать тип транзакции',
    isActiveFilter,
    setIsActiveFilter,
    searchParams?.operation_types?.[0],
  );
  const getPriceSearch = Hooks.useColumnSearch<TSellerHistoryEntity>(
    searchPriceHandler,
    'amount',
    isActiveFilter,
    setIsActiveFilter,
  );

  const filteredColumns: ColumnsType<TSellerHistoryEntity> = columns!
    .filter(i => (i.key ? visibleColumns[i.key] : true))
    .map(column => {
      switch (column.key) {
        case 'amount':
          return { ...column, ...getPriceSearch(column.key, column.title) };
        case 'created_at':
          return { ...column, ...getDateSearch(column.key, column.title) };
        case 'supplier_name':
          return { ...column, ...getSupplierSearch(column.key, column.title) };
        case 'pavilion_name':
          return { ...column, ...getPavilionSearch(column.key, column.title) };
        case 'operation_type_name':
          return { ...column, ...getStatusSearch(column.key, column.title) };
        case 'created_by':
          return { ...column, ...getUserSearch(column.key, column.title) };
        default:
          return column;
      }
    });

  return (
    <div>
      <div className={styles.searchSettings}>
        <div className={styles.balances}>
          Начислено: {balances?.totalIn || '-'} ₽, Выплачено: {balances?.totalOut || '-'} ₽
        </div>
        <div className={styles.options}>
          <Button onClick={onExport} disabled={loading}>Экспорт в Excel</Button>
          <Tooltip
            overlayStyle={{
              position: 'fixed',
            }}
            title='Очистить все фильтры'
          >
            <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
          </Tooltip>
          <TableSettings
            columns={columns}
            visibleColumnsKeys={visibleColumns}
            setVisibleColumnsKeys={setVisibleColumns}
          />
        </div>
      </div>
      <Table
        className={styles.table}
        rowKey={record => record.id}
        bordered
        dataSource={data || []}
        columns={filteredColumns}
        pagination={{
          onChange: handleChangePage,
          onShowSizeChange: handleChangePageSize,
          current: searchParams?.pagination.page || 1,
          pageSize: searchParams?.pagination.per_page || PER_PAGE,
          total,
          showSizeChanger: true,
        }}
        scroll={{ x: 1200 }}
        locale={{ emptyText: <Empty description='Нет данных' /> }}
        loading={loading}
      />
    </div>
  );
};
