import { TPagination, TSimpleNameObject } from 'src/utils';

import api from '../api';
import { AxiosResponseHeaders } from 'axios';

export type TGetSellerBalancesResponse = {
  balances: TSellerBalanceEntity[];
};

export type TSellerBalanceEntity = {
  userId: number;
  userName: string;
  balance: number;
  pavilionName: string;
  tel: string;
};

export type TGetSellerHistoryRequest = {
  userId?: number;
  date_from?: string;
  date_to?: string;
  task_ids?: string[];
  operation_types?: string[];
  amount?: number;
  supplier_id?: number;
  pavilion_id?: number;
  created_by?: number;
  pagination: {
    per_page: number;
    page: number;
  };
};

export type TGetSellerHistoryResponse = {
  records: TSellerHistoryEntity[];
  totalIn: number;
  totalOut: number;
  pagination: TPagination;
};

export type TSellerHistoryEntity = {
  id: number;
  amount: number;
  operation_type_id: number;
  operation_type_name: string;
  comment: string;
  recipient_id: number;
  recipient_name: string;
  supplier_id?: string;
  supplier_name?: string;
  donor_id: number;
  donor_name: string;
  pavilion_name?: string;
  external_id: string;
  created_at: string;
  created_by?: string;
};

export type TUpdateSellerBalanceRequest = {
  userId: number;
  amount: number;
  reason: string;
  operation_type_id: number;
};

export type TUpdateSellerBalanceResponse = {
  balance: number;
  assetBalances: number[];
};

export type TGetSellerOperationTypesResponse = {
  types: TSimpleNameObject[];
};

export const getSellerBalances = (): Promise<TGetSellerBalancesResponse> =>
  api.get('/seller-balances').then(response => response.data);

export const exportSellerBalances = (): Promise<{url: string;}> =>
  api.get('/seller-balances/export').then(response => response.data);

export const getSellerHistory = (data: TGetSellerHistoryRequest): Promise<TGetSellerHistoryResponse> =>
  api.post('/seller-history', data).then(response => response.data);

export const updateSellerBalance = (data: TUpdateSellerBalanceRequest): Promise<TUpdateSellerBalanceResponse> =>
  api.post('/seller-balance-correction', data).then(response => response.data);

export const getSellerOperationTypes = (): Promise<TGetSellerOperationTypesResponse> =>
  api.get('/seller-operation-types').then(response => response.data);

export const exportSellerHistory = (data: TGetSellerHistoryRequest): Promise<{url: string;}> =>
  api.post('/seller-history/export', data).then(response => response.data);
