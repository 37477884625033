import { Button, Empty, Table } from 'antd';
import React, { useState } from 'react';
import { TableSettings } from 'src/entities/tableSettings';
import { ColumnsType } from 'antd/es/table';
import { TSellerBalanceEntity } from 'src/shared/api/queries/sellerBillingApi';

import { getColumns } from '../../PaymentsWithdrawalPage.utils';
import styles from '../../PaymentsWithdrawalPage.module.less';
import { usePaymentsWithdrawal } from '../../hooks/usePaymentsWithdrawal';
import { WithdrawalModal } from '../WithdrawalModal';

export const Content: React.FC = () => {
  const { balances, loading, onExport } = usePaymentsWithdrawal();
  const [withdrawal, setWithdrawal] = useState<TSellerBalanceEntity>();

  const summ = balances?.reduce((acc, current) => acc + current.balance / 100, 0) || 0;

  const { columns, initColumnSettings } = getColumns({
    onWithdrawal: setWithdrawal,
  });
  const [visibleColumns, setVisibleColumns] = useState(initColumnSettings);

  const filteredColumns: ColumnsType<TSellerBalanceEntity> = columns!
    .filter(i => (i.key ? visibleColumns[i.key] : true))
    .map(column => {
      switch (column.key) {
        default:
          return column;
      }
    });

  return (
    <div>
      <div className={styles.searchSettings}>
        <div className={styles.balances}>Активно: {new Intl.NumberFormat().format(summ)} ₽</div>
        <div className={styles.options}>
          <Button onClick={onExport} disabled={loading}>
            Экспорт в Excel
          </Button>
          <TableSettings
            columns={columns}
            visibleColumnsKeys={visibleColumns}
            setVisibleColumnsKeys={setVisibleColumns}
          />
        </div>
      </div>
      <Table
        className={styles.table}
        rowKey={record => JSON.stringify(record)}
        bordered
        dataSource={balances || []}
        columns={filteredColumns}
        scroll={{ x: 1200 }}
        locale={{ emptyText: <Empty description='Нет данных' /> }}
        loading={loading}
      />
      {withdrawal && <WithdrawalModal item={withdrawal} onClose={() => setWithdrawal(undefined)} />}
    </div>
  );
};
